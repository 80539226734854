import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./home";
import Page404 from "./page404";
import Header from "./header";
import Footer from "./footer";
import Cookie from "./cookie";
import Offline from "./offline";
import "./i18n";

function App() {
    return (
      <BrowserRouter>
        <Suspense fallback={"...is loading"}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="" element={<Page404 />} />
            <Route path="*" element={<Page404 />} />
            <Route path="offline" element={<Offline />} />
          </Routes>
          <Footer />
          <Cookie />
        </Suspense>
      </BrowserRouter>
    );
}

export default App;
