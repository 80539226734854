
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const Footer = () => {
      const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer_container md:mx-8"></div>
      <hr className="footer_divider" />

      <div className="footer_bottom mx-8">
        <span className="copyright text-sm text-grey-200">
          <a
            href="https://www.marinakozlovice.cz/"
            target="_blank"
            rel="noreferrer"
            className="footer_list-link text-sm text-grey-200"
          >
            © {moment().format("YYYY")} Marina Kozlovice
          </a>
          . {t("rightreserved")}
        </span>
        <ul className="footer_list">
          <li className="footer_list-item"></li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
