import React from "react";
import { useTranslation } from "react-i18next";

const Page404 = () => {
    const { t } = useTranslation();
  document.title = "ERROR 404";
  return (
    <div className="main bg-white-200 rounded-lg">
      <div className="flex flex-col md:flex-row md:m-6 w-auto md:w-full">
        <div className="w-full md:w-64 lg:w-80 items-center">
          <img
            src="/images/error404.png"
            className="jump w-48 md:w-64 lg:w-80 m-auto"
            alt="Interní chyba serveru"
          />
        </div>
        <div className="p-3 md:p-10">
          <h3 className="text-blue-500 text-center md:text-start font-bold text-lg">
            {t("error404.notfound")}
          </h3>
          <h2 className="text-blue-700 text-center md:text-start font-bold text-6xl leading-[108px]">
            {t("error404.error")}
          </h2>
          <p>{t("error404.text")}</p>
        </div>
      </div>
    </div>
  );
};

export default Page404;
