import React from "react";


function Home() {
    document.title = "Marina Kozlovice";
  return (
    <div className="main">
      <img
        src="/images/logo.png"
        className="image-full animate__bounceIn"
        alt="Marina Kozlovcie"
      />
    </div>
  );
}

export default Home;
