import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Cookie = () => {
const { t } = useTranslation();
  const [cookies, setCookies] = useState(getCookie("euCookieLaw"));
    const [open, setOpen] = useState(false);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  }

  const handleClose = (e) => {
    e.preventDefault();
    setCookie("euCookieLaw", true, 30);
    setCookies(getCookie("euCookieLaw"));
  };

  function setCookie(name, value, days) {
    let expiress;
    if (days) {
      let date = new Date();

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expiress = "; expires=" + date.toGMTString();
    } else expiress = "";
    document.cookie = name + "=" + value + expiress + "; path=/";
  }
  /**
  function eraseCookie(name) {
    setCookie(name, "", -1);
  }
 */
 const openModal = (e) => {
       e.preventDefault();
setOpen(true);
}

  const getBanner = () => {
          
    return (
      <>
        <div id="myModal" className={open ? "modal block" : "hidden"}>
          <div className="modal-content">
            <div>
              <h4 className="text-center">
                {t("cookies-note.advanc-setting")}
              </h4>
              <p className="text-center my-2 text-sm">
                {t("cookies-note.text5")}
              </p>
              <div className="border-b-2 border-b-white-300 grid w-full grid-cols-4 gap-1 p-2">
                <div>
                  <img
                    src="/images/butonek.png"
                    alt=""
                    className="min-w-16 w-16"
                    loading="lazy"
                  />
                </div>
                <div className="col-span-3">
                  <strong>{t("cookies-note.nezbytne")}</strong>
                  <br />
                  <span className="text-xs">{t("cookies-note.text6")}</span>
                </div>
              </div>
              <div className="border-b-2 border-b-white-300 grid w-full grid-cols-4 gap-1 p-2">
                <div>
                  <img
                    src="/images/butonekoff.png"
                    alt=""
                    className="w-16 min-w-16"
                    loading="lazy"
                  />
                </div>
                <div className="col-span-3">
                  <strong>{t("cookies-note.marketingove")}</strong>
                  <br />
                  <span className="text-xs">
                    {t("cookies-note.text4")}
                    <strong className="text-red-500">
                      [{t("cookies-note.nevyuzivame")}]
                    </strong>
                  </span>
                </div>
              </div>
              <div className="border-b-2 border-b-white-300 grid w-full grid-cols-4 gap-1 p-2">
                <div>
                  <img
                    src="/images/butonekoff.png"
                    alt=""
                    className="min-w-16 w-16"
                    loading="lazy"
                  />
                </div>
                <div className="col-span-3">
                  <strong>{t("cookies-note.analyticke")}</strong>
                  <br />
                  <span className="text-xs">
                    {t("cookies-note.text3")}
                    <strong className="text-red-500">
                      [{t("cookies-note.nevyuzivame")}]
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="close btn btn-success"
                data-dismiss="alert"
                onClick={handleClose}
              >
                {t("cookies-note.agree")}
              </button>
            </div>
          </div>
        </div>
        <div id="eu-cookie-law" className={"eu-cookie-law"} role="alert">
          <button
            type="button"
            className="close"
            onClick={handleClose}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="grid w-full grid-cols-1 md:grid-cols-4 gap-1">
            <div className="col-span-3">
              <strong>{t("cookies-note.usecookies")}</strong>
              <p>
                {t("cookies-note.text1")}
                <br />
                {t("cookies-note.text2")}
              </p>
            </div>
            <div className="flex flex-row md:flex-col px-6">
              <button
                type="button"
                className="close btn btn-success"
                data-dismiss="alert"
                onClick={handleClose}
              >
                {t("cookies-note.agree")}
              </button>
              <button
                type="button"
                className="btn btn-settings"
                data-bs-toggle="modal"
                data-bs-target="#myModal"
                onClick={openModal}
              >
                {t("cookies-note.predvolby")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return !cookies ? getBanner() : "";
};



export default Cookie;
