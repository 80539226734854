

export function registerServiceWorker() {

  window.addEventListener("beforeunload", function (event) {
    console.log("Webpage onload.");
  });

  if ( "serviceWorker" in navigator) {
    /** process.env.NODE_ENV === 'production' */
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.origin) {
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      navigator.serviceWorker
        .register(swUrl, {
          scope: "/",
        })
        /**    .then((registration) => {
          let serviceWorker;
          if (registration.installing) {
            serviceWorker = registration.installing;
            document.querySelector("#kind").textContent = "installing";
          } else if (registration.waiting) {
            serviceWorker = registration.waiting;
            document.querySelector("#kind").textContent = "waiting";
          } else if (registration.active) {
            serviceWorker = registration.active;
            document.querySelector("#kind").textContent = "active";
          }
          if (serviceWorker) {
            // logState(serviceWorker.state);
            serviceWorker.addEventListener("statechange", (e) => {
              // logState(e.target.state);
            });
          }
        })*/
        .then(() => navigator.serviceWorker.ready)
        .then((res) => {
          res.addEventListener("updatefound", () => {
            const newWorker = res.installing;

            newWorker.addEventListener("statechange", () => {
              // newWorker.state has changed
            });
          });
          console.log("service worker registered");
        })
        .catch((err) => console.log("service worker not registered", err));

      navigator.serviceWorker.addEventListener("controllerchange", () => {});
    });
  }
}



export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}