import React from "react";

const Offline = () => {
  document.title = "Jte offline";
  return (
    <div className="main bg-white-200 rounded-lg">
      <div className="flex flex-col md:flex-row md:m-6 w-auto md:w-full">
        <div className="w-full md:w-64 lg:w-80 items-center">
          <img
            src="/images/no-internet.png"
            className="jump w-48 md:w-64 lg:w-80 m-auto"
            alt="Interní chyba serveru"
          />
        </div>
        <div className="p-3 md:p-10">
          <h3 className="text-blue-500 font-bold text-center md:text-start text-lg">
            Žádný internet
          </h3>
          <h2 className="text-blue-700 font-bold text-center md:text-start text-4xl leading-[108px]">
            Jste offline
          </h2>
          <p>
            Požadovaná stránka není v režimu offline k dispozici. Připojte se k
            internetu a aktualizujte stránku, abyste ji zobrazili online.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Offline;
