import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Header = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  const location = useLocation(); 
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
    setChecked(false);
  }, [location]);

const lang = i18next.language;

  return (
    <div className="top-nav">
      <div className="top-nav-logo">
        <Link to="/">
          <img src="/images/logo.png" alt="Marina Kozlovcie" />
        </Link>
      </div>
      <input
        className="menu-btn"
        type="checkbox"
        id="menu-toggle"
        checked={checked}
        onChange={handleChange}
      />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu">
        <li
          className={
            url === "/"
              ? "bg-blue-700 text-white-100"
              : "hover:text-white-100 hover:bg-blue-700"
          }
        >
          <Link to="/" onClick={setChecked}>
            {t("home")}
          </Link>
        </li>
        <li className={"hover:text-white-100 hover:bg-blue-700"}>
            <Link
              onClick={() =>
                i18next.changeLanguage(lang === "en" ? "cs-CZ" : "en")
              }
            >
              <i className="icon" data-icon="lang"></i>
              {lang === "en" ? "CZ" : "EN"}
            </Link>
        </li>
      </ul>
    </div>
  );
};

export default Header;
